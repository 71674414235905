<div class="custom-search-bg" style="width: 100%;">


    <div class="row h-100 custom-search-type-complex justify-content-center">

      <div class="col-12 the-card">

        <div class="row">
          <div class="col-12 d-lg-block d-none input-drop">
            <h6>Bairro ou Cidade</h6>
            <!-- <input type="text" class="form-control"> -->
            <input type="text" class="col form-control" placeholder="Bairro ou Cidade"
              #inputTextRef
              [(ngModel)]="inputSimpleSearch"
              (input)="logn_changeSimpleSearch(inputSimpleSearch)"
              [ngModelOptions]="{standalone: true}"/>
            <!-- <datalist class="imoveisDatalist">
              <option *ngFor="let item of autocompletes" [value]="item.value">{{item.value}}</option>
            </datalist> -->
              <app-custom-dropdown
                #customDrop
                *ngIf="bairrosAndCidades.visible"
                [bairros]="bairrosAndCidades.bairros"
                [cidades]="bairrosAndCidades.cidades"
                (changeValueEmit)="logn_changeInputSearch($event)"
              ></app-custom-dropdown>
          </div>
          <!-- <div class="col-12 col-md-4">
            <h6>Cidade</h6>
            <mat-form-field>
              <mat-select placeholder="Cidade" (selectionChange)="changeCidade($event)" [(ngModel)]="customSearch.cidade">
                <mat-option *ngFor="let city of cidades" [value]="city" >{{city}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <h6>Bairro</h6>
            <mat-form-field multiple>
              <mat-select placeholder="Bairro" multiple (selectionChange)="changeBairro($event)" [(ngModel)]="bairrosSelecionados">
                <mat-option *ngFor="let bairro of customSearch.bairros" [value]="bairro">{{bairro}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="col-12">
            <h6>Tipo do imóvel</h6>
            <mat-form-field>
              <mat-select placeholder="Todos os imóveis" multiple (selectionChange)="changeTipo($event)" [(ngModel)]="tiposSelecionados">
                <mat-option *ngFor="let tipo of (finalidade === 'residencial' ? TIPOS_RESIDENCIAL : TIPOS_COMERCIAL)" [value]="tipo">{{ tipo | titlecase }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <h6>Valor</h6>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="price_min">Preço mínimo</label>
                  <input type="text" class="form-control" id="price_min" [(ngModel)]="customSearch.precos.min" [textMask]="{mask: MASKS.currency.textMask}" (focusout)="inputPriceFocusOut($event, 0)" placeholder="Ex.: R$ 100"/>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="price_max">Preço máximo</label>
                  <input type="text" class="form-control" id="price_max"  [(ngModel)]="customSearch.precos.max" [textMask]="{mask: MASKS.currency.textMask}" (focusout)="inputPriceFocusOut($event, 1)" placeholder="Ex.: R$ 1.000.000"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 order-2">
            <h6>Quartos</h6>
            <div class="boll-selector">
              <button [class.current]="customSearch.dormitorios == 1" (click)="customSearch.dormitorios === 1 ? customSearch.dormitorios = null : customSearch.dormitorios = 1"><span>1</span>
              </button>
              <button [class.current]="customSearch.dormitorios == 2" (click)="customSearch.dormitorios === 2 ? customSearch.dormitorios = null : customSearch.dormitorios = 2"><span>2</span>
              </button>
              <button [class.current]="customSearch.dormitorios == 3" (click)="customSearch.dormitorios === 3 ? customSearch.dormitorios = null : customSearch.dormitorios = 3"><span>3</span>
              </button>
              <button [class.current]="customSearch.dormitorios == 4" (click)="customSearch.dormitorios === 4 ? customSearch.dormitorios = null : customSearch.dormitorios = 4"><span>4+</span>
              </button>
            </div>
          </div>
          <div class="col-12 order-2">
            <h6>Banheiros</h6>
            <div class="boll-selector">
              <button [class.current]="customSearch.banheiros == 1" (click)="customSearch.banheiros === 1 ? customSearch.banheiros = null : customSearch.banheiros = 1"><span>1</span>
              </button>
              <button [class.current]="customSearch.banheiros == 2" (click)="customSearch.banheiros === 2 ? customSearch.banheiros = null : customSearch.banheiros = 2"><span>2</span>
              </button>
              <button [class.current]="customSearch.banheiros == 3" (click)="customSearch.banheiros === 3 ? customSearch.banheiros = null : customSearch.banheiros = 3"><span>3</span>
              </button>
              <button [class.current]="customSearch.banheiros == 4" (click)="customSearch.banheiros === 4 ? customSearch.banheiros = null : customSearch.banheiros = 4"><span>4+</span>
              </button>
            </div>
          </div>
          <div class="col-12 order-2">
            <h6>Garagem</h6>
            <div class="boll-selector">
              <button [class.current]="customSearch.garagem == 1" (click)="customSearch.garagem === 1 ? customSearch.garagem = 0 : customSearch.garagem = 1"><span>1</span>
              </button>
              <button [class.current]="customSearch.garagem == 2" (click)="customSearch.garagem === 2 ? customSearch.garagem = 0 : customSearch.garagem = 2"><span>2</span>
              </button>
              <button [class.current]="customSearch.garagem == 3" (click)="customSearch.garagem === 3 ? customSearch.garagem = 0 : customSearch.garagem = 3"><span>3</span>
              </button>
              <button [class.current]="customSearch.garagem == 4" (click)="customSearch.garagem === 4 ? customSearch.garagem = 0 : customSearch.garagem = 4"><span>4+</span>
              </button>
            </div>
          </div>
          <div class="col-12 order-1">
            <h6>Área</h6>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="area_min">Mínimo 20m²</label>
                  <input type="text" class="form-control" id="area_min"   [(ngModel)]="customSearch.area.min" [textMask]="{mask: MASKS.number.textMask}" (focusout)="inputPriceFocusOut($event, 2)" placeholder="Ex.: 10 m²"/>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="area_max">Máximo 1.000 m²</label>
                  <input type="text" class="form-control" id="area_max"  [(ngModel)]="customSearch.area.max" [textMask]="{mask: MASKS.number.textMask}" (focusout)="inputPriceFocusOut($event, 3)" placeholder="Ex.: 1000 m²"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-5 d-none d-sm-flex">
          <div class="col-12">
            <!-- <button class="button-apply justify-content-center" (click)="applyFilter()"><i class="custom-search"></i>Aplicar
              filtros
            </button> -->
            <button class="button-apply justify-content-center" (click)="logn_FormFilter()"><i class="custom-search"></i>Aplicar
              filtros
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
