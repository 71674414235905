import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent implements OnInit {

  // atributos
  bairros:Array<any> = [];
  cidades:Array<any> = [];
  condominios:Array<any> = [];

  // Props
  @Input() simpleSearch:string = '';
  @Input() autocompletes:Array<any> = [];

  // Emição de eventos
  @Output() closeMobileSearchBar = new EventEmitter();
  @Output() inputSearch = new EventEmitter<string>();
  @Output() selectedOption = new EventEmitter<string>();
  @Output() submitFilter = new EventEmitter();

  // Refs
  @ViewChild('inputElement') input:ElementRef;
  constructor(
    private cdr:ChangeDetectorRef
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.inputFocus();
  }

  inputFocus():void{
    this.input.nativeElement.focus();
    this.cdr.detectChanges();
  }

  handleInput(value:string): void {
    this.outputNewSimpleSearch(value);
    this.autoComplete(value);
  }

  closeModal(){
    this.input.nativeElement.blur();
    this.closeMobileSearchBar.emit();
  }

  outputNewSimpleSearch(value:string){
    this.inputSearch.emit(value);
  }

  autoComplete(value:string){
    if(value === null || value === undefined) return;
    if(value.trim() === "") return;

    if(value.length >= 3){
      const lowerValue = value.toLowerCase();
      this.bairros = this.filterLocales(lowerValue, 'bairro');
      this.cidades = this.filterLocales(lowerValue, 'cidade');
      this.condominios = this.filterLocales(lowerValue, 'condomínio');
    } else {
      this.bairros = [];
      this.cidades = [];
      this.condominios = [];
    }
    this.cdr.detectChanges();
  }

  private filterLocales(value:string, type:string): Array<any> {
    return this.autocompletes.filter(item =>
      item.type === type && item.value.toLowerCase().startsWith(value)
    ) || [];
  }

  selectedEvent(value:string){
    this.closeModal();
    this.selectedOption.emit(value);
  }

  search(event:Event):void{
    event.preventDefault();
    this.submitFilter.emit();
    this.closeMobileSearchBar.emit();
  }

}
