<div class="search-mobile d-block d-lg-none">
    <div class="header-mobile">
        <div class="container">
            <span>Buscar imóvel</span>
            <span (click)="closeModal()" class="button-close"></span>
        </div>
    </div>
    <div class="container">
        <form (submit)="search($event)">
            <label>
                <input
                    #inputElement
                    type="search"
                    class="col form-control"
                    placeholder="Bairro ou Cidade"
                    [(ngModel)]="simpleSearch"
                    [ngModelOptions]="{standalone: true}"
                    (input)="handleInput(simpleSearch)"
                />
                <!--
                (keyup)="searchAutocomplete($event)" id="userIdFirstWay" list="dynmicUserIdsImoveis"
                (change)="onSelectAutoComplete($event, simpleSearch)"
                [ngModelOptions]="{standalone: true}" -->
            </label>
        </form>
        <div class="results">
            <ul>
              <li *ngIf="cidades.length > 0">
                  Cidades
                  <ul>
                    <li (click)="selectedEvent(local.value)" *ngFor="let local of cidades">
                      {{ local.value }}
                    </li>
                  </ul>
              </li>
                <li *ngIf="bairros.length > 0">
                    Bairros
                    <ul>
                        <li (click)="selectedEvent(local.value)" *ngFor="let local of bairros">
                            {{ local.value }}
                        </li>
                    </ul>
                </li>

                <li *ngIf="condominios.length > 0">
                    Condomínios
                    <ul>
                        <li (click)="selectedEvent(local.value)" *ngFor="let local of condominios">
                            {{ local.value }}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
