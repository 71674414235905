import { Component, Input, OnInit, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomDropdownComponent implements OnInit {

  @Input() cidades:Array<{type:string, value:string}> = [];
  @Input() bairros:Array<{type:string, value:string}> = [];
  @Output() changeValueEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  changeValue(event:any, value:string): void {
    this.changeValueEmit.emit(value);
  }

}
